<template>
	<div class="my-order">
		<mallHeader />
		<div class="mall-my-order container">
			<ul class="breadcrumb">
				<li>
					<a href="#" @click="$router.push('/ori-mall')"
						><i class="el-icon-s-home"></i
					></a>
				</li>
				<li><i class="el-icon-arrow-right"></i></li>
				<li><a href="#">我的订单</a></li>
			</ul>
			<el-tabs v-model="activeName" type="card">
				<el-tab-pane label="全部订单" name="first">
					<div class="order-list">
						<div class="order-item" v-for="item in 15" :key="item">
							<div class="head">
								<span>下单时间：2000.04.01</span>
								<span>订单编号：128548915</span>
								<!-- 只有未付款才显示 -->
								<span class="down-time">
									<i class="iconfont icon-down-time"></i>
									<!-- <b>付款截止：123</b> -->
									<b>该订单已经超时</b>
								</span>
							</div>
							<div class="body">
								<div class="column goods">
									<ul>
										<li>
											<div class="image">
												<img src="@/assets/images/b2.png" alt="" />
											</div>
											<div class="info">
												<p class="name ellipsis-2">
													微星MSI
													冲锋坦克GP76游戏本12代酷睿i7-12700H笔记本电脑16G内存1T固态
													满血显卡 GP76/DDR5/3070Ti/2.5K240
												</p>
												<p class="attr ellipsis">
													选服务
												</p>
											</div>
											<div class="price">¥123.00</div>
											<div class="count">x1</div>
										</li>
									</ul>
								</div>
								<div class="column state">
									<p>待付款</p>
									<!-- <p>
									<a href="javascript:;" class="green">查看物流</a>
								</p>
								<p>
									<a href="javascript:;" class="green">评价商品</a>
								</p>
								<p>
									<a href="javascript:;" class="green">查看评价</a>
								</p> -->
								</div>
								<div class="column amount">
									<p class="red">¥123</p>
									<p>（含运费：¥123）</p>
									<p>在线支付</p>
								</div>
								<div class="column action">
									<!-- <el-button type="primary" size="small">
										立即付款
									</el-button> -->
									<!-- <el-button type="primary" size="small">
									确认收货
								</el-button> -->
									<p>
										<a href="javascript:;" @click="$router.push('/checkOrderDetail')">查看详情</a>
									</p>
									<p>
										<a href="javascript:;" @click="dialogOrder = true">取消订单</a>
									</p>
									<!-- <p>
									<a href="javascript:;" class="del">删除</a>
								</p>
								<p>
									<a href="javascript:;">
										再次购买
									</a>
								</p>
								<p>
									<a href="javascript:;">申请售后</a>
								</p> -->
								</div>
							</div>
						</div>
						<el-pagination background layout="prev, pager, next" :total="15">
						</el-pagination>
					</div>
				</el-tab-pane>
				<el-tab-pane label="待付款" name="second"
					><div class="order-list">
						<div class="order-item" v-for="item in 15" :key="item">
							<div class="head">
								<span>下单时间：123</span>
								<span>订单编号：12</span>
								<!-- 只有未付款才显示 -->
								<span class="down-time">
									<i class="iconfont icon-down-time"></i>
									<!-- <b>付款截止：123</b> -->
									<b>该订单已经超时</b>
								</span>
							</div>
							<div class="body">
								<div class="column goods">
									<ul>
										<li>
											<div class="image">
												<img src="@/assets/images/b2.png" alt="" />
											</div>
											<div class="info">
												<p class="name ellipsis-2">
													123
												</p>
												<p class="attr ellipsis">
													123
												</p>
											</div>
											<div class="price">¥123</div>
											<div class="count">x1</div>
										</li>
									</ul>
								</div>
								<div class="column state">
									<p>待付款</p>
									<!-- <p>
									<a href="javascript:;" class="green">查看物流</a>
								</p>
								<p>
									<a href="javascript:;" class="green">评价商品</a>
								</p>
								<p>
									<a href="javascript:;" class="green">查看评价</a>
								</p> -->
								</div>
								<div class="column amount">
									<p class="red">¥123</p>
									<p>（含运费：¥123）</p>
									<p>在线支付</p>
								</div>
								<div class="column action">
									<el-button type="primary" size="small">
										立即付款
									</el-button>
									<!-- <el-button type="primary" size="small">
									确认收货
								</el-button> -->
									<p>
										<a href="javascript:;">查看详情</a>
									</p>
									<p>
										<a href="javascript:;">取消订单</a>
									</p>
									<!-- <p>
									<a href="javascript:;" class="del">删除</a>
								</p>
								<p>
									<a href="javascript:;">
										再次购买
									</a>
								</p>
								<p>
									<a href="javascript:;">申请售后</a>
								</p> -->
								</div>
							</div>
						</div>
						<el-pagination background layout="prev, pager, next" :total="15">
						</el-pagination></div
				></el-tab-pane>
				<el-tab-pane label="待发货" name="third"
					><div class="order-list">
						<div class="order-item" v-for="item in 15" :key="item">
							<div class="head">
								<span>下单时间：123</span>
								<span>订单编号：12</span>
								<!-- 只有未付款才显示 -->
								<span class="down-time">
									<i class="iconfont icon-down-time"></i>
									<!-- <b>付款截止：123</b> -->
									<b>该订单已经超时</b>
								</span>
							</div>
							<div class="body">
								<div class="column goods">
									<ul>
										<li>
											<div class="image">
												<img src="@/assets/images/b2.png" alt="" />
											</div>
											<div class="info">
												<p class="name ellipsis-2">
													123
												</p>
												<p class="attr ellipsis">
													123
												</p>
											</div>
											<div class="price">¥123</div>
											<div class="count">x1</div>
										</li>
									</ul>
								</div>
								<div class="column state">
									<p>待发货</p>
									<!-- <p>
									<a href="javascript:;" class="green">查看物流</a>
								</p>
								<p>
									<a href="javascript:;" class="green">评价商品</a>
								</p>
								<p>
									<a href="javascript:;" class="green">查看评价</a>
								</p> -->
								</div>
								<div class="column amount">
									<p class="red">¥123</p>
									<p>（含运费：¥123）</p>
									<p>在线支付</p>
								</div>
								<div class="column action">
									<!-- <el-button type="primary" size="small">
										立即付款
									</el-button> -->
									<!-- <el-button type="primary" size="small">
									确认收货
								</el-button> -->
									<p>
										<a href="javascript:;">查看详情</a>
									</p>
									<!-- <p>
										<a href="javascript:;">取消订单</a>
									</p> -->
									<!-- <p>
									<a href="javascript:;" class="del">删除</a>
								</p> -->
									<p>
										<a href="javascript:;">
											再次购买
										</a>
									</p>
									<!-- <p>
									<a href="javascript:;">申请售后</a>
								</p> -->
								</div>
							</div>
						</div>
						<el-pagination background layout="prev, pager, next" :total="15">
						</el-pagination></div
				></el-tab-pane>
				<el-tab-pane label="待收货" name="fourth"
					><div class="order-list">
						<div class="order-item" v-for="item in 15" :key="item">
							<div class="head">
								<span>下单时间：123</span>
								<span>订单编号：12</span>
								<!-- 只有未付款才显示 -->
								<span class="down-time">
									<i class="iconfont icon-down-time"></i>
									<!-- <b>付款截止：123</b> -->
									<b>该订单已经超时</b>
								</span>
							</div>
							<div class="body">
								<div class="column goods">
									<ul>
										<li>
											<div class="image">
												<img src="@/assets/images/b2.png" alt="" />
											</div>
											<div class="info">
												<p class="name ellipsis-2">
													123
												</p>
												<p class="attr ellipsis">
													123
												</p>
											</div>
											<div class="price">¥123</div>
											<div class="count">x1</div>
										</li>
									</ul>
								</div>
								<div class="column state">
									<p>待收货</p>
									<!-- <p>
									<a href="javascript:;" class="green">查看物流</a>
								</p> -->
									<!-- <p>
									<a href="javascript:;" class="green">评价商品</a>
								</p> -->
									<!-- <p>
									<a href="javascript:;" class="green">查看评价</a>
								</p> -->
								</div>
								<div class="column amount">
									<p class="red">¥123</p>
									<p>（含运费：¥123）</p>
									<p>在线支付</p>
								</div>
								<div class="column action">
									<!-- <el-button type="primary" size="small">
										立即付款
									</el-button> -->
									<el-button type="primary" size="small">
										确认收货
									</el-button>
									<p>
										<a href="javascript:;">查看详情</a>
									</p>
									<p>
										<a href="javascript:;">取消订单</a>
									</p>
									<!-- <p>
									<a href="javascript:;" class="del">删除</a>
								</p>
								<p>
									<a href="javascript:;">
										再次购买
									</a>
								</p>
								<p>
									<a href="javascript:;">申请售后</a>
								</p> -->
								</div>
							</div>
						</div>
						<el-pagination background layout="prev, pager, next" :total="15">
						</el-pagination></div
				></el-tab-pane>
				<el-tab-pane label="待评价" name="fiveth"
					><div class="order-list">
						<div class="order-item" v-for="item in 15" :key="item">
							<div class="head">
								<span>下单时间：123</span>
								<span>订单编号：12</span>
								<!-- 只有未付款才显示 -->
								<span class="down-time">
									<i class="iconfont icon-down-time"></i>
									<!-- <b>付款截止：123</b> -->
									<b>该订单已经超时</b>
								</span>
							</div>
							<div class="body">
								<div class="column goods">
									<ul>
										<li>
											<div class="image">
												<img src="@/assets/images/b2.png" alt="" />
											</div>
											<div class="info">
												<p class="name ellipsis-2">
													123
												</p>
												<p class="attr ellipsis">
													123
												</p>
											</div>
											<div class="price">¥123</div>
											<div class="count">x1</div>
										</li>
									</ul>
								</div>
								<div class="column state">
									<p>待评价</p>
									<!-- <p>
									<a href="javascript:;" class="green">查看物流</a>
								</p> -->
									<p>
										<a href="javascript:;" class="green">评价商品</a>
									</p>
									<!-- <p>
									<a href="javascript:;" class="green">查看评价</a>
								</p> -->
								</div>
								<div class="column amount">
									<p class="red">¥123</p>
									<p>（含运费：¥123）</p>
									<p>在线支付</p>
								</div>
								<div class="column action">
									<el-button type="primary" size="small">
										立即付款
									</el-button>
									<!-- <el-button type="primary" size="small">
									确认收货
								</el-button> -->
									<p>
										<a href="javascript:;">查看详情</a>
									</p>
									<!-- <p>
										<a href="javascript:;">取消订单</a>
									</p> -->
									<!-- <p>
									<a href="javascript:;" class="del">删除</a>
								</p> -->
									<p>
										<a href="javascript:;">
											再次购买
										</a>
									</p>
									<p>
										<a href="javascript:;">申请售后</a>
									</p>
								</div>
							</div>
						</div>
						<el-pagination background layout="prev, pager, next" :total="15">
						</el-pagination></div
				></el-tab-pane>
				<el-tab-pane label="已完成" name="sixth"
					><div class="order-list">
						<div class="order-item" v-for="item in 15" :key="item">
							<div class="head">
								<span>下单时间：123</span>
								<span>订单编号：12</span>
								<!-- 只有未付款才显示 -->
								<span class="down-time">
									<i class="iconfont icon-down-time"></i>
									<!-- <b>付款截止：123</b> -->
									<b>该订单已经超时</b>
								</span>
							</div>
							<div class="body">
								<div class="column goods">
									<ul>
										<li>
											<div class="image">
												<img src="@/assets/images/b2.png" alt="" />
											</div>
											<div class="info">
												<p class="name ellipsis-2">
													123
												</p>
												<p class="attr ellipsis">
													123
												</p>
											</div>
											<div class="price">¥123</div>
											<div class="count">x1</div>
										</li>
									</ul>
								</div>
								<div class="column state">
									<p>待付款</p>
									<!-- <p>
									<a href="javascript:;" class="green">查看物流</a>
								</p>
								<p>
									<a href="javascript:;" class="green">评价商品</a>
								</p>
								<p>
									<a href="javascript:;" class="green">查看评价</a>
								</p> -->
								</div>
								<div class="column amount">
									<p class="red">¥123</p>
									<p>（含运费：¥123）</p>
									<p>在线支付</p>
								</div>
								<div class="column action">
									<el-button type="primary" size="small">
										立即付款
									</el-button>
									<!-- <el-button type="primary" size="small">
									确认收货
								</el-button> -->
									<p>
										<a href="javascript:;">查看详情</a>
									</p>
									<p>
										<a href="javascript:;">取消订单</a>
									</p>
									<!-- <p>
									<a href="javascript:;" class="del">删除</a>
								</p>
								<p>
									<a href="javascript:;">
										再次购买
									</a>
								</p>
								<p>
									<a href="javascript:;">申请售后</a>
								</p> -->
								</div>
							</div>
						</div>
						<el-pagination background layout="prev, pager, next" :total="15">
						</el-pagination></div
				></el-tab-pane>
				<el-tab-pane label="已取消" name="seventh"
					><div class="order-list">
						<div class="order-item" v-for="item in 15" :key="item">
							<div class="head">
								<span>下单时间：123</span>
								<span>订单编号：12</span>
								<!-- 只有未付款才显示 -->
								<span class="down-time">
									<i class="iconfont icon-down-time"></i>
									<!-- <b>付款截止：123</b> -->
									<b>该订单已经超时</b>
								</span>
							</div>
							<div class="body">
								<div class="column goods">
									<ul>
										<li>
											<div class="image">
												<img src="@/assets/images/b2.png" alt="" />
											</div>
											<div class="info">
												<p class="name ellipsis-2">
													123
												</p>
												<p class="attr ellipsis">
													123
												</p>
											</div>
											<div class="price">¥123</div>
											<div class="count">x1</div>
										</li>
									</ul>
								</div>
								<div class="column state">
									<p>待付款</p>
									<!-- <p>
									<a href="javascript:;" class="green">查看物流</a>
								</p>
								<p>
									<a href="javascript:;" class="green">评价商品</a>
								</p>
								<p>
									<a href="javascript:;" class="green">查看评价</a>
								</p> -->
								</div>
								<div class="column amount">
									<p class="red">¥123</p>
									<p>（含运费：¥123）</p>
									<p>在线支付</p>
								</div>
								<div class="column action">
									<el-button type="primary" size="small">
										立即付款
									</el-button>
									<!-- <el-button type="primary" size="small">
									确认收货
								</el-button> -->
									<p>
										<a href="javascript:;">查看详情</a>
									</p>
									<p>
										<a href="javascript:;">取消订单</a>
									</p>
									<!-- <p>
									<a href="javascript:;" class="del">删除</a>
								</p>
								<p>
									<a href="javascript:;">
										再次购买
									</a>
								</p>
								<p>
									<a href="javascript:;">申请售后</a>
								</p> -->
								</div>
							</div>
						</div>
						<el-pagination background layout="prev, pager, next" :total="15">
						</el-pagination></div
				></el-tab-pane>
			</el-tabs>
			<el-dialog
				title="取消订单"
				:visible.sync="dialogOrder"
				width="30%"
				:before-close="handleClose"
			>
				<span class="delOrder">取消订单后，本单享有的优惠可能会一并取消，是否继续？</span>
				<div class="delWhy">请选择取消订单的原因(必选) :</div>
        <div class="delwhyChoose">
          <ul>
            <li><div class="box boxActive" >配送信息有误</div><div class="box">商品买错了</div></li>
            <li><div class="box">重复下单/误下单</div><div class="box">忘记使用优惠卷</div></li>
            <li><div class="box">其他渠道价格更低</div><div class="box">不想买了</div></li>
          </ul>
        </div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogOrder = false">取 消</el-button>
					<el-button type="primary" @click="dialogOrder = false"
						>确 定</el-button
					>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import mallHeader from './header.vue'
export default {
	name: 'product',
	components: {
		mallHeader,
	},
	data() {
		return {
			activeName: 'first',
			dialogOrder: false,
		}
	},
	mounted() {},
	methods: {
		handleClose(done) {
			this.$confirm('确认关闭？')
				.then(() => {
					done()
				})
				.catch(() => {})
		},
	},
}
</script>

<style lang="scss" scoped>
.my-order {
	color: #666;
	background-color: #f0f3ef;
  font-family: fangsong;
	li {
		list-style: none;
	}
	.container {
		width: 1200px;
		margin: 0 auto;
	}
	.mall-my-order {
		.breadcrumb {
			margin: 23px 0;
			background-color: transparent;
			padding: 0;
			display: inline-block;
			width: 100%;
			line-height: 100%;
			li {
				display: inline-block;
				padding-left: 8px;
				margin-right: 7px;
				position: relative;
				a {
					display: block;
					padding: 0;
					color: #999;
					text-decoration: none;
					font-size: 14px;
				}
				a:hover {
					color: #005ea6;
				}
				i {
					font-size: 14px;
				}
			}
		}
		.el-tabs {
			background-color: #fff;
			padding: 20px;
			.order-list {
				.order-item {
					margin-bottom: 20px;
					border: 1px solid #f5f5f5;
					.head {
						height: 50px;
						line-height: 50px;
						background: #f5f5f5;
						padding: 0 20px;
						overflow: hidden;
						span {
							margin-right: 20px;
							&.down-time {
								margin-right: 0;
								float: right;
								i {
									vertical-align: middle;
									margin-right: 3px;
								}
								b {
									vertical-align: middle;
									font-weight: normal;
								}
							}
						}
						.del {
							margin-right: 0;
							float: right;
							color: #999;
						}
					}
					.body {
						display: flex;
						align-items: stretch;
						.column {
							border-left: 1px solid #f5f5f5;
							text-align: center;
							padding: 20px;
							> p {
								padding-top: 10px;
							}
							&:first-child {
								border-left: none;
							}
							&.goods {
								flex: 1;
								padding: 0;
								align-self: center;
								ul {
									li {
										border-bottom: 1px solid #f5f5f5;
										padding: 10px;
										display: flex;
										&:last-child {
											border-bottom: none;
										}
										.image {
											border: 1px solid #f5f5f5;
											img {
												width: 70px;
												height: 70px;
											}
										}
										.info {
											text-align: left;
											padding: 0 10px;
											p {
												margin-bottom: 5px;
												&.name {
												}
												&.attr {
													color: #999;
													font-size: 12px;
													span {
														margin-right: 5px;
													}
												}
											}
										}
										.price {
											width: 100px;
										}
										.count {
											width: 80px;
										}
									}
								}
							}
							&.state {
								width: 120px;
								.green {
									color: #005ea6;
								}
							}
							&.amount {
								width: 200px;
								.red {
									color: skyblue;
								}
							}
							&.action {
								width: 140px;
								a {
									display: block;
									&:hover {
										color: #005ea6;
									}
								}
							}
						}
					}
				}
				::v-deep {
					.el-button--primary {
						background-color: #005ea6;
						border-color: #005ea6;
					}
					.el-button--primary:hover {
						background-color: #d12;
						border-color: #d12;
					}
					.el-pagination.is-background .el-pager li:not(.disabled).active {
						background-color: #005ea6;
						color: #ffffff;
					}
					.el-pagination.is-background .el-pager li:not(.disabled):hover {
						color: #005ea6;
					}
					.el-pagination {
						display: flex;
						justify-content: center;
					}
				}
			}
		}
    .el-dialog{
      .delOrder{
        font-size: 16px;
      }
      .delWhy{
        color: #999;
        margin-top: 10px;
      }
      .delwhyChoose{
       ul{
         padding: 0;
          li{
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          .box{
            display: inline-block;
            width: 250px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            border: 1px solid #d7d7d7;
            font-size: 16px;
            cursor: pointer;
          }
          .box:hover{
            border-color: #005ea6;
            background-color: #8dbff8;
          }
          .boxActive{
            border-color: #005ea6;
            background-color: #8dbff8;
          }
        }
       }
      }
    }
		::v-deep {
			.el-tabs__item:hover {
				color: #005ea6;
			}
			.el-tabs__item.is-active {
				color: #005ea6;
			}
      .el-dialog__header{
        border-bottom: 1px solid #d7d7d7;
      }
		}
	}
}
</style>
