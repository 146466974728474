<template>
	<div class="mall-content-header">
		<div class="header-top container">
			<span
				>Welcome to SuperMarket! Wrap new offers / gift every single day on
				Weekends - New Coupon code:</span
			>
			<span class="red"> Happy2018</span>
			<div class="header-top-right">
				<!-- <span class="money">
					<el-dropdown>
						<span class="el-dropdown-link">
							下拉菜单<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item icon="el-icon-plus">黄金糕</el-dropdown-item>
							<el-dropdown-item icon="el-icon-check">双皮奶</el-dropdown-item>
							<el-dropdown-item icon="el-icon-circle-check"
								>蚵仔煎</el-dropdown-item
							>
						</el-dropdown-menu>
					</el-dropdown>
				</span>
				<span class="language">
					<el-dropdown>
						<span class="el-dropdown-link">
							下拉菜单<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item icon="el-icon-plus">黄金糕</el-dropdown-item>
							<el-dropdown-item icon="el-icon-circle-plus"
								>蚵仔煎</el-dropdown-item
							>
						</el-dropdown-menu>
					</el-dropdown>
				</span> -->
			</div>
		</div>
		<div class="banner">
			<div class="header-middle container">
				<el-row :gutter="20">
					<el-col :span="6"
						><div class="grid-content bg-purple">
							<div class="logo">
								<img src="@/assets/images/catalog/logo1.png" alt="" />
							</div></div
					></el-col>
					<el-col :span="12"
						><div class="grid-content bg-purple">
							<div class="select">
								<el-input placeholder="请输入内容" class="input-with-select">
									<el-select
										v-model="value"
										placeholder="请选择"
										slot="prepend"
									>
										<el-option
											v-for="item in options"
											:key="item.value"
											:label="item.label"
											:value="item.value"
										>
										</el-option>
									</el-select>
									<el-button slot="append" icon="el-icon-search"></el-button>
								</el-input>
							</div></div
					></el-col>
					<el-col :span="6"
						><div class="grid-content bg-purple">
							<div class="refresh">
								<span class="el-icon-refresh refresh-i"></span>
								<span class="el-icon-star-off refresh-i"></span>
								<el-button
									icon="el-icon-s-goods"
									circle
									size="mini"
									@click="$router.push('/shoppingCart')"
								></el-button>
								<div class="shopcart-inner">
									<p class="text-shopping-cart">购物车</p>
									<span class="total-shopping-cart cart-total-full"
										><span class="items_cart">02</span
										><span class="items_carts">$162.00</span></span
									>
								</div>
							</div>
						</div></el-col
					>
				</el-row>
			</div>
			<div class="header-bottom container">
				<el-row>
					<el-col :span="4"
						><div class="grid-content bg-purple">
							<div class="left">
								<i class="el-icon-s-fold"></i>
								<span class="categories">所有类别</span>
								<i class="el-icon-arrow-down"></i>
							</div></div
					></el-col>
					<el-col :span="12"
						><div class="grid-content bg-purple-light">
							<div class="menu">
								<el-menu
									class="el-menu-demo"
									mode="horizontal"
									background-color="#005ea6"
									text-color="#fff"
									active-text-color="#fff"
								>
									<el-submenu index="1">
										<template slot="title">首页</template>
										<div class="home" style="display: flex; background: #fff">
											<div class="home1" style="margin: 20px">
												<img src="@/assets/images/home-1.jpg" alt="" />
												<p style="color: #fff; text-align: center">
													Home Page1
												</p>
											</div>
											<div class="home1" style="margin: 20px">
												<img src="@/assets/images/home-1.jpg" alt="" />
												<p style="color: #fff; text-align: center">
													Home Page1
												</p>
											</div>
											<div class="home1" style="margin: 20px">
												<img src="@/assets/images/home-1.jpg" alt="" />
												<p style="color: #fff; text-align: center">
													Home Page1
												</p>
											</div>
										</div>
									</el-submenu>
									<el-submenu index="2">
										<template slot="title">分类</template>
										<div
											class="classification"
											style="background: #fff; display: flex"
										>
											<span>
												<ul style="list-style: none; width: 200px">
													<li><a href="#">123</a></li>
													<el-divider></el-divider>
													<li><a href="#">123</a></li>
													<li><a href="#">123</a></li>
													<li><a href="#">123</a></li>
												</ul>
											</span>
											<span
												><ul style="list-style: none; width: 200px">
													<li><a href="#">123</a></li>
													<el-divider></el-divider>
													<li><a href="#">123</a></li>
													<li><a href="#">123</a></li>
													<li><a href="#">123</a></li>
												</ul></span
											>
											<span
												><ul style="list-style: none; width: 200px">
													<li><a href="#">123</a></li>
													<el-divider></el-divider>
													<li><a href="#">123</a></li>
													<li><a href="#">123</a></li>
													<li><a href="#">123</a></li>
												</ul></span
											>
											<span
												><ul style="list-style: none; width: 200px">
													<li><a href="#">123</a></li>
													<el-divider></el-divider>
													<li><a href="#">123</a></li>
													<li><a href="#">123</a></li>
													<li><a href="#">123</a></li>
												</ul></span
											>
										</div>
									</el-submenu>
									<el-submenu index="3">
										<template slot="title">页面</template>
										<div class="page" style="background: #fff; display: flex">
											<span
												><ul style="list-style: none; width: 200px">
													<li><a href="#">123</a></li>
													<li><a href="#">123</a></li>
													<li><a href="#">123</a></li>
													<li><a href="#">123</a></li>
												</ul></span
											>
											<span
												><ul style="list-style: none; width: 200px">
													<li><a href="#">123</a></li>
													<li><a href="#">123</a></li>
													<li><a href="#">123</a></li>
													<li><a href="#">123</a></li>
												</ul></span
											>
										</div>
									</el-submenu>
									<el-submenu index="4">
										<template slot="title">目录</template>
										<div
											class="directory"
											style="background: #fff; display: flex"
										>
											<span
												><ul
													style="list-style: none; width: 200px; margin: 20px"
												>
													<li>
														<img
															style="width: 175px; height: 150px"
															src="@/assets/images/image-1.jpg"
															alt=""
														/>
													</li>
													<li><a href="#">123</a></li>
													<el-divider></el-divider>
													<li><a href="#">123</a></li>
													<li><a href="#">123</a></li>
												</ul></span
											>
											<span
												><ul
													style="list-style: none; width: 200px; margin: 20px"
												>
													<li>
														<img
															style="width: 175px; height: 150px"
															src="@/assets/images/image-2.jpg"
															alt=""
														/>
													</li>
													<li><a href="#">123</a></li>
													<el-divider></el-divider>
													<li><a href="#">123</a></li>
													<li><a href="#">123</a></li>
												</ul></span
											>
											<span
												><ul
													style="list-style: none; width: 200px; margin: 20px"
												>
													<li>
														<img
															style="width: 175px; height: 150px"
															src="@/assets/images/image-3.jpg"
															alt=""
														/>
													</li>
													<li><a href="#">123</a></li>
													<el-divider></el-divider>
													<li><a href="#">123</a></li>
													<li><a href="#">123</a></li>
												</ul></span
											>
											<span
												><ul
													style="
                              list-style: none;
                              width: 200px;
                              margin: 20px 50px 20px 20px;
                            "
												>
													<li>
														<img
															style="width: 175px; height: 150px"
															src="@/assets/images/image-4.jpg"
															alt=""
														/>
													</li>
													<li><a href="#">123</a></li>
													<el-divider></el-divider>
													<li><a href="#">123</a></li>
													<li><a href="#">123</a></li>
												</ul></span
											>
										</div>
									</el-submenu>
									<el-menu-item index="5" @click="$router.push('/userInfo')"
										>个人中心</el-menu-item
									>
									<el-menu-item index="6">博客</el-menu-item>
								</el-menu>
							</div>
						</div></el-col
					>
					<el-col :span="8"
						><div class="grid-content bg-purple">
							<div class="right">
								<div class="right-left">
									<img src="@/assets/images/icon/锁.svg" alt="" />
									<span class="login"><a href="#">登录</a></span>
									<span class="or">or</span>
									<span class="register"><a href="#">注册</a></span>
								</div>
								<div class="right-middle" @click="$router.push('/myOrder')">
									<img src="@/assets/images/icon/货车.svg" alt="" />
									<span><a href="#">跟踪你的订单</a></span>
								</div>
								<div class="right-right">
									<img src="@/assets/images/icon/电话.svg" alt="" />
									<span><a href="#">123456789</a></span>
								</div>
							</div>
						</div></el-col
					>
				</el-row>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			options: [
				{
					value: '选项1',
					label: '黄金糕',
				},
				{
					value: '选项2',
					label: '双皮奶',
				},
				{
					value: '选项3',
					label: '蚵仔煎',
				},
				{
					value: '选项4',
					label: '龙须面',
				},
				{
					value: '选项5',
					label: '北京烤鸭',
				},
			],
			value: '',
		}
	},
}
</script>
<style scoped lang="scss">
.container {
	width: 1200px;
	margin: 0 auto;
}
.mall-content-header {
  font-family: fangsong;
	.header-top {
		color: #828282;
		// background-color: #fff;
		font-size: 12px;
		min-height: 36px;
		span {
			line-height: 36px;
		}
		.red {
			color: #ff5e00;
		}
		.header-top-right {
			font-size: 12px;
			float: right;
			.el-dropdown {
				font-size: 12px;
			}
			.el-dropdown:hover {
				color: #ff5e00;
			}
			.money {
				padding-right: 20px;
				border-right: 1px solid #ccc;
			}
			.language {
				padding-left: 20px;
			}
		}
	}
	.banner {
		// background: url(../../../assets/images/bg/bg-header1.jpg) no-repeat left 36px;;
		background: #005ea6;
		height: 129px;
		padding-top: 20px;
		.header-middle {
			height: 50px;
			// background-color: #005ea6;
			.select {
				line-height: 50px;
				.el-select {
					width: 130px;
				}
				.el-button {
					background-color: #222;
					color: #fff;
				}
				.el-button:hover {
					background-color: #ff5e00;
				}
			}
			.logo {
				img {
					width: 225px;
				}
			}
			.refresh {
				height: 50px;
				position: relative;
				float: right;
				.refresh-i {
					font-size: 26px;
					font-weight: 900;
					color: #fff;
					margin: 0px 35px 0 2px;
					line-height: 50px;
				}
				.refresh-i:hover {
					color: #222;
				}
				.el-button {
					font-size: 20px;
					color: #005ea6;
				}
				.shopcart-inner {
					color: #fff;
					margin-left: 15px;
					// margin-top: 8px;
					width: 40px;
					position: absolute;
					font-size: 12px;
					top: 12px;
					left: 150px;
					.items_cart {
						font-size: 14px;
						position: absolute;
						top: -14px;
						left: -14px;
						width: 20px;
						height: 20px;
						background-color: #222;
						border-radius: 20px;
						line-height: 20px;
						text-align: center;
					}
					.items_carts {
						position: absolute;
						top: 14px;
						left: 0;
					}
				}
			}
		}
		.header-bottom {
			padding-top: 10px;
			height: 40px;
			.left {
				background-color: #222;
				color: #fff;
				text-align: center;
				// font-weight: 700;
				height: 40px;
				// margin-top: 9px;
				border-radius: 3px;
				margin-top: 9px;
				line-height: 40px;
				.categories {
					margin: 0 40px 0 20px;
				}
			}
			.menu {
				::v-deep {
					.el-menu--horizontal > .el-submenu .el-submenu__title {
						height: 40px;
            margin-top: 9px;
            line-height: 40px;
					}
					.el-menu--horizontal > .el-menu-item {
						height: 40px;
            margin-top: 9px;
            line-height: 40px;
            border: none;
					}
					.el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
						color: #fff;
					}

				}
			}
			.right {
				height: 49px;
				color: #fff;
				font-size: 14px;
				display: flex;
				padding-top: 25px;
				.right-left {
					margin-right: 10px;
					img {
						width: 12px;
						height: 12px;
					}
					.login a {
						padding-left: 4px;
						color: #fff;
					}
					.login a:hover {
						color: #222;
					}
					.or {
						padding: 0 5px;
					}
					.register a {
						color: #fff;
						border-right: 1px solid #ccc;
						padding-right: 10px;
					}
					.register a:hover {
						color: #222;
					}
				}
				.right-middle {
					img {
						width: 12px;
						height: 12px;
					}
					a {
						color: #fff;
						margin-left: 4px;
						border-right: 1px solid #ccc;
						padding-right: 10px;
					}
				}
				.right-right {
					margin-left: 10px;
					img {
						width: 12px;
						height: 12px;
					}
					a {
						color: #fff;
						margin-left: 4px;
					}
				}
			}
		}
	}
}
</style>
